define("m08-2020/controllers/x3dcontroller", ["exports", "m08-2020/mixins/model-validator", "jquery"], function (_exports, _modelValidator, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    geometrieController: Ember.inject.controller('geometrie'),
    optionenController: Ember.inject.controller('optionen'),
    downloadcenterController: Ember.inject.controller('verbindungsmittel'),
    vorholzlaenge: 1.85,
    aktualisiereGrafik: function () {
      var self = this;
      var geometrieData = self.get('applicationController').get('model').geometrie.objectAt(0);
      var x3dTraegerData = self.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'traeger');
      var defaultValue = geometrieData.get('h_ef') === "" ? 20 : Number(geometrieData.get('h_ef')) + 0.1;
      var hoehe = geometrieData.get('TimberElement_h') === "" ? defaultValue : Number(geometrieData.get('TimberElement_h'));
      var breite = geometrieData.get('TimberElement_b') === "" ? defaultValue / 2 : Number(geometrieData.get('TimberElement_b'));
      var resttraegerHoehe = geometrieData.get('h_ef') === "" ? hoehe / 2 : Number(geometrieData.get('h_ef'));
      var auflagerAbstand = geometrieData.get('c') === "" ? hoehe / 2 : Number(geometrieData.get('c'));
      var laengeAnschnitt = geometrieData.get('x') === "" ? 0 : Number(geometrieData.get('x'));
      var vorholzlaenge = self.get('vorholzlaenge');
      var laenge = auflagerAbstand * laengeAnschnitt > 75 ? (auflagerAbstand + laengeAnschnitt) * 1.5 : 75;
      var punkte = self.berechneTraegerGrundflaeche(hoehe, laenge, resttraegerHoehe, auflagerAbstand, laengeAnschnitt, vorholzlaenge); // console.log(breite);

      self.setPunkteToX3dDefault('traeger', punkte, breite);
      punkte = self.berechneAuflagerGrundflaeche(hoehe, laenge, resttraegerHoehe, auflagerAbstand, laengeAnschnitt, vorholzlaenge);
      self.setPunkteToX3dDefault('auflager', punkte, breite); //massketten

      self.setMasskettenPunkte(hoehe, breite, resttraegerHoehe, auflagerAbstand, laengeAnschnitt); //text

      self.setMasskettentexte(geometrieData.get('TimberElement_h'), geometrieData.get('TimberElement_b'), geometrieData.get('h_ef'), geometrieData.get('c'), geometrieData.get('x'));
    },
    setPunkteToX3dDefault: function (bauteil, punkte, breite) {
      var self = this;
      var bauteilData = self.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', bauteil);
      punkte.forEach((item, i) => {
        bauteilData.get('punkt').findBy('id', bauteil + item.id).set('x', item.x);
        bauteilData.get('punkt').findBy('id', bauteil + item.id).set('y', item.y);
        bauteilData.get('punkt').findBy('id', bauteil + item.id).set('z', item.z);
      });
      bauteilData.get('platte').findBy('id', bauteil).set('thickness', breite);
    },
    berechneTraegerGrundflaeche: function (hoehe, laenge, resthoehe, auflagerabstand, laengeAnschnitt, vorholzlaenge) {
      var p0 = {
        id: "p0",
        x: -this.get('vorholzlaenge'),
        y: hoehe,
        z: 0
      };
      var p1 = {
        id: "p1",
        x: -this.get('vorholzlaenge'),
        y: hoehe - resthoehe,
        z: 0
      };
      var p2 = {
        id: "p2",
        x: auflagerabstand,
        y: hoehe - resthoehe,
        z: 0
      };
      var p3 = {
        id: "p3",
        x: auflagerabstand + laengeAnschnitt,
        y: 0,
        z: 0
      };
      var p4 = {
        id: "p4",
        x: laenge,
        y: 0,
        z: 0
      };
      var p5 = {
        id: "p5",
        x: laenge,
        y: hoehe,
        z: 0
      };
      return [p0, p1, p2, p3, p4, p5];
    },
    berechneAuflagerGrundflaeche: function (hoehe, laenge, resthoehe, auflagerabstand, laengeAnschnitt, vorholzlaenge) {
      hoehe = auflagerabstand !== 0 ? hoehe - resthoehe : 0;
      var p0 = {
        id: "p0",
        x: 0,
        y: hoehe,
        z: 0
      };
      var p1 = {
        id: "p1",
        x: -1.5,
        y: hoehe - 1.8,
        z: 0
      };
      var p2 = {
        id: "p2",
        x: 1.5,
        y: hoehe - 1.8,
        z: 0
      };
      return [p0, p1, p2];
    },
    setMasskettenPunkte: function (hoehe, breite, resthoehe, auflagerabstand, laengeAnschnitt, vorholzlaenge) {
      var self = this;
      var bauteile = self.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
      bauteile.findBy('id', 'massketteHoehe').get('punkt').findBy('id', 'massketteHoehep1').set('y', hoehe);
      bauteile.findBy('id', 'massketteBreite').get('punkt').findBy('id', 'massketteBreitep0').set('y', hoehe);
      bauteile.findBy('id', 'massketteBreite').get('punkt').findBy('id', 'massketteBreitep1').set('y', hoehe);
      bauteile.findBy('id', 'massketteBreite').get('punkt').findBy('id', 'massketteBreitep1').set('z', breite);
      bauteile.findBy('id', 'massketteResttraegerhoehe').get('punkt').findBy('id', 'massketteResttraegerhoehep0').set('y', hoehe);
      bauteile.findBy('id', 'massketteResttraegerhoehe').get('punkt').findBy('id', 'massketteResttraegerhoehep1').set('y', hoehe - resthoehe);
      bauteile.findBy('id', 'massketteAuflagerabstand').get('punkt').findBy('id', 'massketteAuflagerabstandp0').set('z', breite);
      bauteile.findBy('id', 'massketteAuflagerabstand').get('punkt').findBy('id', 'massketteAuflagerabstandp1').set('z', breite);
      bauteile.findBy('id', 'massketteAuflagerabstand').get('punkt').findBy('id', 'massketteAuflagerabstandp1').set('x', auflagerabstand);
      bauteile.findBy('id', 'massketteAnschnittlaenge').get('punkt').findBy('id', 'massketteAnschnittlaengep0').set('z', breite);
      bauteile.findBy('id', 'massketteAnschnittlaenge').get('punkt').findBy('id', 'massketteAnschnittlaengep1').set('z', breite);
      bauteile.findBy('id', 'massketteAnschnittlaenge').get('punkt').findBy('id', 'massketteAnschnittlaengep0').set('x', auflagerabstand);
      bauteile.findBy('id', 'massketteAnschnittlaenge').get('punkt').findBy('id', 'massketteAnschnittlaengep1').set('x', auflagerabstand + laengeAnschnitt);
    },
    setMasskettentexte: function (hoehe, breite, resthoehe, auflagerabstand, laengeAnschnitt) {
      var self = this;
      var bauteile = self.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
      bauteile.findBy('id', 'massketteHoehe').get('masskette').objectAt(0).set('masstext', hoehe === "" ? " " : hoehe);
      bauteile.findBy('id', 'massketteBreite').get('masskette').objectAt(0).set('masstext', breite === "" ? " " : breite);
      bauteile.findBy('id', 'massketteResttraegerhoehe').get('masskette').objectAt(0).set('masstext', resthoehe === "" ? " " : resthoehe);
      bauteile.findBy('id', 'massketteAuflagerabstand').get('masskette').objectAt(0).set('masstext', auflagerabstand === "" ? " " : auflagerabstand);
      bauteile.findBy('id', 'massketteAnschnittlaenge').get('masskette').objectAt(0).set('masstext', laengeAnschnitt === "" ? " " : laengeAnschnitt);
    },
    indexedFaceset: function (bauteil) {
      // console.log("###  x3dcontroller  ###");
      // console.log(bauteil.id);
      return "<transform scaleorientation=\"0,0,0,0\" scale=\"1,1,1\" center=\"0 0 0\" bboxsize=\"-1,-1,-1\" bboxcenter=\"0 0 0\" rotation=\"0 0 0 0 \" translation=\"0 0 0\" render=\"true\"><shape ispickable=\"0\" bboxsize=\"-1,-1,-1\" bboxcenter=\"0,0,0\" render=\"true\"><appearance sorttype=\"auto\" alphaclipthreshold=\"0.1\"><material specularcolor=\"0.52 0.385 0.02\" shininess=\"0.2\" emissivecolor=\"" + this.farbe(bauteil) + "\" ambientintensity=\"0.2\" transparency=\"0.65\" diffusecolor=\"" + this.farbe(bauteil) + "\" ></material></appearance><indexedfaceset ccw=\"true\" colorpervertex=\"false\" colorindex=\"" + this.colorindex(bauteil) + "\" coordindex=\"" + this.coordIndex(bauteil, false) + "\" solid=\"true\" usegeocache=\"true\" lit=\"false\" normalpervertex=\"true\" normalupdatemode=\"fast\" convex=\"true\" normalindex=\"0\" texcoordindex=\"0\"><coordinate point=\"" + this.koordinaten(bauteil) + " \"></coordinate></indexedfaceset></shape><shape><indexedlineSet coordIndex=\"" + this.coordIndex(bauteil, true) + "\"><coordinate point=\"" + this.koordinaten(bauteil) + "\"/></indexedlineSet></shape></transform>";
    },
    koordinaten: function (item) {
      var p0 = '0 0 0',
          p1 = '0 0 0',
          p2 = '0 0 0',
          p3 = '0 0 0',
          p4 = '0 0 0',
          p5 = '0 0 0',
          p6 = '0 0 0',
          p7 = '0 0 0',
          p8 = '0 0 0',
          p9 = '0 0 0';
      var cords = "";
      var x0 = 0;
      var y0 = 0;
      var z0 = 0;
      var rx = 0;
      var ry = 0;
      var rz = 0;
      var dicke = 0;

      for (var n = 0; n <= 1; n++) {
        for (var i = 0; i < item.get('punkt').length; i++) {
          // console.log(i+" ::: "+(i+2)+" ::: "+(i+1));
          var i2 = i + 2 > item.get('punkt').length - 1 ? i + 2 - item.get('punkt').length : i + 2;
          var i1 = i + 1 > item.get('punkt').length - 1 ? i + 1 - item.get('punkt').length : i + 1; // console.log(i+" ::: "+(i2)+" ::: "+(i1));
          // console.log(this.getPosition(item.get('punkt').objectAt(i).get('x'), item.get('punkt').objectAt(i).get('y'), item.get('punkt').objectAt(i2).get('x'), item.get('punkt').objectAt(i2).get('y'), item.get('punkt').objectAt(i1).get('x'), item.get('punkt').objectAt(i1).get('y')));

          x0 = Number(item.get('punkt').objectAt(i).get('x'));
          y0 = Number(item.get('punkt').objectAt(i).get('y'));
          z0 = Number(item.get('punkt').objectAt(i).get('z'));
          cords = cords + (x0 + rx * dicke) + ' ' + (y0 + ry * dicke) + ' ' + (z0 + rz * dicke) + ' ';
        }

        rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));
        dicke = Number(item.get('platte').objectAt(0).get('thickness'));
      } // if(Number(item.get('id')) === 0){
      //   console.log(cords);
      // }


      return cords;
    },
    coordIndex: function (item, kontur) {
      // var values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";
      // console.log(item.id +" ::: "+ item.get('punkt').length);
      var values = "";

      if (item.get('punkt').length === 6) {
        if (kontur) {
          values = "0 1 2 3 4 5 0 -1 0 6 -1 1 7 -1 2 8 -1 3 9 -1 4 10 -1 5 11 -1 6 7 8 9 10 11 6 -1";
        } else {
          values = "0 5 2 1 0 -1 2 5 4 3 2 -1 6 7 8 11 6 -1 8 9 10 11 8 -1 0 1 7 6 0 -1 1 2 8 7 1 -1 2 3 9 8 2 -1 3 4 10 9 -1 4 5 11 10 4 -1 0 6 11 5 0 -1";
        }
      } else {
        // Grundfläche
        for (var i = 0; i < item.get('punkt').length; i++) {
          values = values + " " + i;
        }

        values = values + " 0 -1 "; // n-1 Seitenflächen

        for (var i = 0; i < item.get('punkt').length - 1; i++) {
          values = values + i + " " + (i + item.get('punkt').length) + " " + (i + item.get('punkt').length + 1) + " " + (i + 1) + " " + i + " " + -1 + " ";
        } // n.-Seitenfläche


        values = values + 0 + " " + (item.get('punkt').length - 1) + " " + (item.get('punkt').length - 1 + item.get('punkt').length) + " " + item.get('punkt').length + " " + 0 + " " + -1 + " ";
        var arr = []; // Abschlussfläche

        for (var i = 0; i < item.get('punkt').length; i++) {
          arr.push(i + item.get('punkt').length);
        }

        var str = arr.reverse().toString();
        values = values + " " + item.get('punkt').length + " " + str.replace(/,/g, " ") + " -1 "; // console.log(values);
      }

      return values;
    },
    colorindex: function (item) {
      var values = "";

      if (Number(item.get('id')) === 0) {
        values = "0 0 0 0 0 0 0 0 0 0 0 0";
      } else {
        for (var i = 0; i < item.get('punkt').length + 2; i++) {
          values = values + " 0";
        }
      } // console.log(values);


      return values;
    },
    farbe: function (item) {
      // var item = x3d.get('bauteile').findBy('id', name);
      var values;
      var rot = 0,
          gruen = 0,
          blau = 0;
      var materialtextur = Number(item.get('platte').objectAt(0).get('matid')); // console.log('materialtextur: '+materialtextur);

      switch (materialtextur) {
        case 1:
          //Beton
          // console.log('Beton');
          rot = 0;
          gruen = 0.52;
          blau = 0.88;
          break;

        case 10:
          //Holz
          // console.log('Holz');
          rot = 0.52;
          gruen = 0.385;
          blau = 0.02;
          break;

        case 20:
          //Schalung
          // console.log('Schalung');
          rot = 0.8;
          gruen = 0.65;
          blau = 0;
          break;
      }

      values = rot + ' ' + gruen + ' ' + blau;
      return values;
    },
    indexedLineset: function (bauteil) {
      // console.log("###  x3dcontroller indexedLineset ###");
      return "<transform scaleorientation=\"0,0,0,0\" scale=\"1,1,1\" center=\"0 0 0\" bboxsize=\"-1,-1,-1\" bboxcenter=\"0 0 0\" render=\"true\" translation=\"0,0,0\" rotation=\"0,0,0,0\"><shape render=\"true\" bboxcenter=\"0,0,0\" bboxsize=\"-1,-1,-1\" ispickable=\"true\"><appearance sorttype=\"auto\" alphaclipthreshold=\"0.1\"><material emissivecolor=\"0 0 0\" ambientintensity=\"0.2\" diffusecolor=\"0.8,0.8,0.8\" shininess=\"0.2\" specularcolor=\"0,0,0\"></material></appearance><indexedlineset coordindex=\"1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1\" solid=\"true\" ccw=\"true\" usegeocache=\"true\" lit=\"true\" colorpervertex=\"true\" ><coordinate name=\"traeger\" typ=\"breite\" point=\"" + this.massketteKoordinaten(bauteil) + "\"></coordinate></indexedlineset></shape></transform>";
    },
    massketteKoordinaten: function (item) {
      var bauteile = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
      var x = 0;
      var y = 0;
      var z = 0;
      var cords;
      var p1 = new Object();
      var p2 = new Object();
      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;
      var ueberstand = Number(this.get('ueberstand'));
      var verstaerkung = 0;
      p1.x = item.get('punkt').objectAt(1).get('x');
      p1.y = item.get('punkt').objectAt(1).get('y');
      p1.z = item.get('punkt').objectAt(1).get('z');
      p2.x = item.get('punkt').objectAt(0).get('x');
      p2.y = item.get('punkt').objectAt(0).get('y');
      p2.z = item.get('punkt').objectAt(0).get('z'); // console.log('item punkte p1, p2:', p1, p2);

      var richtungsvektor1 = new Object();
      var richtungsvektor2 = new Object();
      var sichtEbene = "AnsichtXY";

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtXZ";
      } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtYZ";
      }

      sichtEbene = 'masskette' + sichtEbene + item.id.substring(0, item.id.length - 3);
      richtungsvektor1.x = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
      richtungsvektor1.y = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
      richtungsvektor1.z = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));
      richtungsvektor2 = richtungsvektor1;
      var ebene = Number(item.get('masskette').objectAt(0).get('ebene'));
      cords = this.getVektorKoordinaten(p1, p2, richtungsvektor1, richtungsvektor2, ebene);
      return cords;
    },
    getVektorKoordinaten: function (punkt1, punkt2, r1, r2, ebene) {
      var dr = 0.2 * (ebene - 1);
      var b05 = 0.5;
      var b1 = 1;
      var b5 = 5;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 6;
      var verschiebung = b5 * (ebene - 1) + dr;
      var p0 = Number(punkt1.x) + verschiebung * Number(r1.x) + ' ' + (Number(punkt1.y) + verschiebung * Number(r1.y)) + ' ' + (Number(punkt1.z) + verschiebung * Number(r1.z));
      var p1 = Number(punkt2.x) + verschiebung * Number(r2.x) + ' ' + (Number(punkt2.y) + verschiebung * Number(r2.y)) + ' ' + (Number(punkt2.z) + verschiebung * Number(r2.z));
      var p2 = Number(punkt2.x) + (b6 + verschiebung) * Number(r2.x) + ' ' + (Number(punkt2.y) + (b6 + verschiebung) * Number(r2.y)) + ' ' + (Number(punkt2.z) + (b6 + verschiebung) * Number(r2.z));
      var p3 = Number(punkt1.x) + (b6 + verschiebung) * Number(r1.x) + ' ' + (Number(punkt1.y) + (b6 + verschiebung) * Number(r1.y)) + ' ' + (Number(punkt1.z) + (b6 + verschiebung) * Number(r1.z));
      var p4 = this.getXYZ(punkt1, punkt2, r1, r2, b5 + verschiebung, b1);
      var p5 = this.getXYZ(punkt2, punkt1, r2, r1, b5 + verschiebung, b1);
      var p6 = this.getXYZ(punkt2, punkt1, r2, r1, b45 + verschiebung, b05);
      var p8 = this.getXYZ(punkt1, punkt2, r1, r2, b45 + verschiebung, -b05);
      var p7 = this.getXYZ(punkt2, punkt1, r2, r1, b55 + verschiebung, -b05);
      var p9 = this.getXYZ(punkt1, punkt2, r1, r2, b55 + verschiebung, b05);
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    getXYZ: function (punkt1, punkt2, r1, r2, richtungsAbstand, seitenAbstand) {
      var p1_2 = {
        x: Number(punkt1.x) + richtungsAbstand * Number(r1.x),
        y: Number(punkt1.y) + richtungsAbstand * Number(r1.y),
        z: Number(punkt1.z) + richtungsAbstand * Number(r1.z)
      };
      var p2_2 = {
        x: Number(punkt2.x) + richtungsAbstand * Number(r2.x),
        y: Number(punkt2.y) + richtungsAbstand * Number(r2.y),
        z: Number(punkt2.z) + richtungsAbstand * Number(r2.z)
      };
      var r3 = {
        x: p2_2.x - p1_2.x,
        y: p2_2.y - p1_2.y,
        z: p2_2.z - p1_2.z
      };
      var lr3 = this.vektorLaenge(r3.x, r3.y, r3.z);

      if (lr3 === 0) {
        // console.log("!!!!! Bei "+this.get('name')+" Division durch 0 !!!!!");
        //
        // console.log('punkt1');
        // console.log(punkt1);
        // console.log('punkt2');
        // console.log(punkt2);
        // console.log('Länge von punkt2 zu punkt1');
        // console.log(lr3);
        // console.log(' ');
        lr3 = 1;
      }

      var r3Einheitsvektor = {
        x: r3.x / lr3,
        y: r3.y / lr3,
        z: r3.z / lr3
      };
      var x = p1_2.x + r3Einheitsvektor.x * (lr3 + seitenAbstand);
      var y = p1_2.y + r3Einheitsvektor.y * (lr3 + seitenAbstand);
      var z = p1_2.z + r3Einheitsvektor.z * (lr3 + seitenAbstand);
      return x + ' ' + y + ' ' + z;
    },
    vektorLaenge: function (x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    }
  });

  _exports.default = _default;
});