define("m08-2020/controllers/optionen", ["exports", "m08-2020/mixins/object-validator"], function (_exports, _objectValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_objectValidator.default, (_obj = {
    intl: Ember.inject.service(),
    // _min_n0: "",
    // _min_n90: "",
    // _min_a1cPlatte: "",
    // _min_a2cPlatte: "",
    // _sicherheitsAbstand: "",
    openFromFile: false,
    setztiefe: "0.0",
    setztiefeArt: 1,
    einschraubrichtung: false,
    ttmin_n0: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999";
      },

      set(key, value) {
        return value;
      }

    }),
    ttmin_n90: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999";
      },

      set(key, value) {
        return value;
      }

    }),
    ttmin_a1cPlatte: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttmin_a2cPlatte: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttsicherheitsAbstand: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttSetztiefe: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    validations: {
      // _min_n0: {
      //   numericality: {
      //     onlyInteger: true,
      //     greaterThanOrEqualTo: 1,
      //     lessThanOrEqualTo: 999,
      //     message: "!",
      //   }
      // },
      // _min_n90: {
      //   numericality: {
      //     onlyInteger: true,
      //     greaterThanOrEqualTo: 1,
      //     lessThanOrEqualTo: 999,
      //     message: "!",
      //   }
      // },
      // _min_a1cPlatte: {
      //   numericality: {
      //     greaterThanOrEqualTo: 0.0,
      //     lessThanOrEqualTo: 999.0,
      //     message: "!",
      //   }
      // },
      // _min_a2cPlatte: {
      //   numericality: {
      //     greaterThanOrEqualTo: 0.0,
      //     lessThanOrEqualTo: 999.0,
      //     message: "!",
      //   }
      // },
      // _sicherheitsAbstand: {
      //   numericality: {
      //     greaterThanOrEqualTo: 0.0,
      //     lessThanOrEqualTo: 999.0,
      //     message: "!",
      //   }
      // },
      setztiefe: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0,
          message: "*"
        }
      }
    },
    einschraubrichtungen: undefined,
    setztiefearten: undefined,
    einschraubrichtungObserver: Ember.computed('einschraubrichtung', function () {
      if (this.get('einschraubrichtung')) {
        return true;
      }

      return false;
    }),
    applicationController: Ember.inject.controller('application'),
    // optionenController: inject('optionen'),
    // lasteinwirkungController: inject('lasteinwirkung'),
    geometrieController: Ember.inject.controller('geometrie'),
    superController: Ember.inject.controller('supercontroller'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    init: function () {
      this._super();

      console.log("inti optionen");
    },
    initialisierung: function () {
      this.setSelectFieldsContent();
      this.set('_min_n0', "1");
      this.set('_min_n90', "1");
      this.set('_min_a1cPlatte', "0.0");
      this.set('_min_a2cPlatte', "0.0");
      this.set('_sicherheitsAbstand', "0.0");
    },
    setSelectFieldsContent: function () {
      var self = this;
      self.setEinschraubrichtungen();
      self.setSetztiefeArten();
    },

    setEinschraubrichtung(value) {
      this.set('einschraubrichtung', einschraubrichtungDB.einschraubrichtung_de.findBy('id', Number(value.target.value)).value);
      console.log(einschraubrichtungDB.einschraubrichtung_de.findBy('id', Number(value.target.value)).value);
      console.log(value.target.value);
    },

    setSetztiefeArt(value) {
      console.log(value.target.value);

      if (Number(value) === 2) {
        this.set('setztiefe', '');
      } else {
        this.set('setztiefe', '0.0');
      }

      this.send('validation', 0, {
        target: {
          name: "setztiefe"
        }
      });
      this.set('setztiefeArt', Number(value.target.value));
    },

    setEinschraubrichtungen: function (indices) {
      var self = this;
      indices = [1, 2];
      self.set('einschraubrichtungen', self.getSelectFieldContent('einschraubrichtungDB', indices, 2));
      self.set('einschraubrichtung', false);
    },
    setSetztiefeArten: function (indices) {
      var self = this;
      indices = [1, 2, 3];
      self.set('setztiefearten', self.getSelectFieldContent('einschraubtiefenDB', indices, self.get('setztiefeArt')));
      self.set('setztiefeArt', 1);
    },
    getSelectFieldContent: function (db, indexes, selectedValue) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({
              id: dbContent[k].id,
              name: this.get('intl').t(dbContent[k].name),
              selected: selectedValue === dbContent[k].id ? true : false
            });
          }
        }
      }

      return tmp;
    },
    getDBContent: function (db) {
      var dbContent = [];

      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de;
          break;

        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;

        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;

        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;

        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;

        case 'einschraubrichtungDB':
          dbContent = einschraubrichtungDB.einschraubrichtung_de;
          break;

        case 'einschraubtiefenDB':
          dbContent = einschraubtiefenDB.einschraubtiefen_de;
          break;
      }

      return dbContent;
    },
    setztiefeDefiniert: Ember.computed('setztiefeArt', function () {
      if (Number(this.get('setztiefeArt')) === 2) {
        return true;
      }

      return false;
    }),
    setValues: function (values) {
      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      this.set('openFromFile', true);
      this.set('_min_n0', values.min_n0);
      this.setX3D(parseInt(values.min_n0), {
        target: {
          name: "_min_n0"
        }
      });
      this.set('_min_n90', values.min_n90);
      this.setX3D(parseInt(values.min_n90), {
        target: {
          name: "_min_n90"
        }
      });
      this.set('_min_a1cPlatte', values.min_a1cPlatte);
      this.setX3D(Number(values.min_a1cPlatte), {
        target: {
          name: "_min_a1cPlatte"
        }
      });
      this.set('_min_a2cPlatte', values.min_a2cPlatte);
      this.setX3D(Number(values.min_a2cPlatte), {
        target: {
          name: "_min_a2cPlatte"
        }
      });
      this.set('_sicherheitsAbstand', values.sicherheitsAbstand);
      this.setX3D(Number(values.sicherheitsAbstand), {
        target: {
          name: "_sicherheitsAbstand"
        }
      });
      this.send('validation');
      this.set('openFromFile', false);
    },
    watchNumericalInputs: Ember.observer('_min_a1cPlatte', '_min_a2cPlatte', '_sicherheitsAbstand', function () {
      var self = this;

      if (!self.openFromFile) {
        if (self.get('_min_a1cPlatte').toString().indexOf(",") !== -1) {
          self.set('_min_a1cPlatte', self.get('_min_a1cPlatte').toString().replace(",", "."));
        }

        if (self.get('_min_a2cPlatte').toString().indexOf(",") !== -1) {
          self.set('_min_a2cPlatte', self.get('_min_a2cPlatte').toString().replace(",", "."));
        }

        if (self.get('_sicherheitsAbstand').toString().indexOf(",") !== -1) {
          self.set('_sicherheitsAbstand', self.get('_sicherheitsAbstand').toString().replace(",", "."));
        }
      }
    }),
    watchComboboxes: Ember.observer('einschraubrichtung', 'setztiefeArt', function () {
      var self = this;
      self.get('superController').resetVerbindungsmittel();
      var optionen = this.get('applicationController').get('model').optionen.objectAt(0);
      optionen.set('einschraubrichtungVonOben', this.get('einschraubrichtung'));
      optionen.set('anordnungVerbindungsmittel', this.get('setztiefeArt'));

      if (self.get('einschraubrichtung')) {
        self.set('ttSetztiefe', self.get('intl').t('wertebereich') + " 0.0 - " + self.get('geometrieController').get('resttraegerhoehe') + " [cm]");
        self.set('validations.setztiefe.numericality.lessThanOrEqualTo', Number(self.get('geometrieController').get('resttraegerhoehe')));
        self.send('validation', 0, {
          target: {
            name: "einschraubrichtung"
          }
        });
      } else {
        var tmp = (parseFloat(self.get('geometrieController').get('hthoehe')).toFixed(1) - parseFloat(self.get('geometrieController').get('resttraegerhoehe')).toFixed(1)).toFixed(1);
        self.set('ttSetztiefe', self.get('intl').t('wertebereich') + " 0.0 - " + tmp + " [cm]");
        self.set('validations.setztiefe.numericality.lessThanOrEqualTo', Number(tmp));
        self.send('validation', 0, {
          target: {
            name: "einschraubrichtung"
          }
        });
      } // self.get('lasteinwirkungController').setnklarray();
      // self.get('lasteinwirkungController').send('validation', self.get('lasteinwirkungController').get('vorbelastung'), {target: {name: "vorbelastung"}});

    }),
    setX3D: function (value, event) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var optionenData = self.get('applicationController').get('model').optionen.objectAt(0);
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case '_min_n0':
          if (self.get('errors').get("_min_n0") === undefined) {
            x3d.set('min_n0', value);
            optionenData.set('min_n0', value);
            x3d.set('_min_n0Eingetragen', true);
          } else {
            x3d.set('_min_n0Eingetragen', false);
          }

          break;

        case '_min_n90':
          if (self.get('errors').get("_min_n90") === undefined) {
            x3d.set('min_n90', value);
            optionenData.set('min_n90', value);
            x3d.set('min_n90Eingetragen', true);
          } else {
            x3d.set('min_n90Eingetragen', false);
          }

          break;

        case '_min_a1cPlatte':
          if (self.get('errors').get("_min_a1cPlatte") === undefined) {
            x3d.set('min_a1cPlatte', value);
            optionenData.set('min_a1cPlatte', value);
            x3d.set('min_a1cPlatteEingetragen', true);
          } else {
            x3d.set('min_a1cPlatteEingetragen', false);
          }

          break;

        case '_min_a2cPlatte':
          if (self.get('errors').get("_min_a2cPlatte") === undefined) {
            x3d.set('min_a2cPlatte', value);
            optionenData.set('min_a2cPlatte', value);
            x3d.set('min_a2cPlatteEingetragen', true);
          } else {
            x3d.set('min_a2cPlatteEingetragen', false);
          }

          break;

        case '_sicherheitsAbstand':
          if (self.get('errors').get("_sicherheitsAbstand") === undefined) {
            x3d.set('sicherheitsAbstand', value);
            optionenData.set('sicherheitsAbstand', value);
            x3d.set('sicherheitsAbstandEingetragen', true);
          } else {
            x3d.set('sicherheitsAbstandEingetragen', false);
          }

          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      this.get('applicationController').zentriertObjekt();
    },

    validation(value, event) {
      var self = this;

      if (!self.openFromFile) {
        this.setX3D(value, event);
      }

      if (self.validate() === true) {
        self.set('displayErrors', false);
        self.get('applicationController').set('optionenInvalid', false);
        var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
        applicationdata.set('treeLoaded', false);
        applicationdata.set('initialized', false);
        applicationdata.set('pdfErstellt', false);
        self.get('downloadcenterController').set('pdfErstellt', false);
        self.get('applicationController').set('verbindungsmittelInvalid', true);
        var optionen = self.get('applicationController').get('model').optionen.objectAt(0);
        optionen.set('setztiefe', parseFloat(self.get('setztiefe')).toFixed(1));
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('optionenInvalid', true);
      }
    },

    _min_n0IsSelected() {
      var min_n0Aktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_n0Aktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_n0Aktiv', !min_n0Aktiv);

      if (min_n0Aktiv === false) {
        document.getElementsByName('_min_n0')[0].setSelectionRange(0, this.get('_min_n0').length);
      }
    },

    _min_n90IsSelected() {
      var min_n90Aktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_n90Aktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_n90Aktiv', !min_n90Aktiv);

      if (min_n90Aktiv === false) {
        document.getElementsByName('_min_n90')[0].setSelectionRange(0, this.get('_min_n90').length);
      }
    },

    _min_a1cPlatteIsSelected() {
      var min_a1cPlatteAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_a1cPlatteAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_a1cPlatteAktiv', !min_a1cPlatteAktiv);

      if (this.get('_min_a1cPlatte') !== "") {
        var work = parseFloat(this.get('_min_a1cPlatte').toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set('_min_a1cPlatte', rounded);
        this.send('validation', rounded, {
          target: {
            name: "_min_a1cPlatte"
          }
        });
      }

      if (min_a1cPlatteAktiv === false) {
        document.getElementsByName('_min_a1cPlatte')[0].setSelectionRange(0, this.get('_min_a1cPlatte').length);
      }
    },

    _min_a2cPlatteIsSelected() {
      var min_a2cPlatteAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_a2cPlatteAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_a2cPlatteAktiv', !min_a2cPlatteAktiv);

      if (this.get('_min_a2cPlatte') !== "") {
        var work = parseFloat(this.get('_min_a2cPlatte').toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set('_min_a2cPlatte', rounded);
        this.send('validation', rounded, {
          target: {
            name: "_min_a2cPlatte"
          }
        });
      }

      if (min_a2cPlatteAktiv === false) {
        document.getElementsByName('_min_a2cPlatte')[0].setSelectionRange(0, this.get('_min_a2cPlatte').length);
      }
    },

    _sicherheitsAbstandIsSelected() {
      var sicherheitsAbstandAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('sicherheitsAbstandAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('sicherheitsAbstandAktiv', !sicherheitsAbstandAktiv);

      if (this.get('_sicherheitsAbstand') !== "") {
        var work = parseFloat(this.get('_sicherheitsAbstand').toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set('_sicherheitsAbstand', rounded);
        this.send('validation', rounded, {
          target: {
            name: "_sicherheitsAbstand"
          }
        });
      }

      if (sicherheitsAbstandAktiv === false) {
        document.getElementsByName('_sicherheitsAbstand')[0].setSelectionRange(0, this.get('_sicherheitsAbstand').length);
      }
    },

    eingabefeldIsSelected(inputName) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var aktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get(inputName + 'Aktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set(inputName + 'Aktiv', !aktiv); // if(aktiv === true){
      //   bauteile.findBy('id', 'masskette'+inputName.charAt(0).toUpperCase() + inputName.slice(1)).get('diffusecolors').objectAt(0).set('rot', 0);
      // }else{
      //   bauteile.findBy('id', 'masskette'+inputName.charAt(0).toUpperCase() + inputName.slice(1)).get('diffusecolors').objectAt(0).set('rot', 1);
      // }

      x3d.set('istAktivHelper', !x3d.get('istAktivHelper'));
      var focused = self.get(inputName + 'Focused');
      self.set(inputName + 'Focused', !self.get(inputName + 'Focused'));

      if (focused === true && self.get(inputName) !== "") {
        var work = parseFloat(this.get(inputName).toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set(inputName, rounded);
        this.send('validation', rounded, {
          target: {
            name: inputName
          }
        });
      }

      if (focused === false && this.get(inputName).length > 0) {
        document.getElementsByName(inputName)[0].setSelectionRange(0, this.get(inputName).length);
      }
    },

    setGrenzwert: function (feldname, grenzwertMax) {
      var self = this;
      self.set('validations._' + feldname + '.numericality.lessThanOrEqualTo', Number(grenzwertMax));
      self.set('tt' + feldname, self.get('intl').t('wertebereich') + " 0 - " + grenzwertMax + " [cm]");
    } // setSchraubenlaenge: function(){
    //   var self = this;
    //
    //   var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
    //   var bauteile = self.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
    //   var propertiesSchraubeStandard1 = bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0);
    //   var length = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y')) - Number(x3d.get('sicherheitsAbstand'));
    //
    //   if(x3d.get('gabellager') === true){
    //     var traegerbreite = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z');
    //     var zinkenbreite = 5;
    //     length = Number(zinkenbreite)/2 + Number(traegerbreite) + Number(zinkenbreite);
    //   }
    //
    //   propertiesSchraubeStandard1.set('length', length);
    //   propertiesSchraubeStandard1.set('threadlengthtip', length);
    //
    // }

  }, (_applyDecoratedDescriptor(_obj, "setEinschraubrichtung", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setEinschraubrichtung"), _obj), _applyDecoratedDescriptor(_obj, "setSetztiefeArt", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setSetztiefeArt"), _obj), _applyDecoratedDescriptor(_obj, "validation", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj), _applyDecoratedDescriptor(_obj, "_min_n0IsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_n0IsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_min_n90IsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_n90IsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_min_a1cPlatteIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_a1cPlatteIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_min_a2cPlatteIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_a2cPlatteIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_sicherheitsAbstandIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_sicherheitsAbstandIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "eingabefeldIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "eingabefeldIsSelected"), _obj)), _obj));

  _exports.default = _default;
});