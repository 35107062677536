define("m08-2020/controllers/supercontroller", ["exports", "m08-2020/mixins/model-validator", "jquery"], function (_exports, _modelValidator, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    geometrieController: Ember.inject.controller('geometrie'),
    optionenController: Ember.inject.controller('optionen'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    verbindungsmittelController: Ember.inject.controller('verbindungsmittel'),
    testAusgabe: function (text) {
      console.log("##### Supercontroller Test #####");
      console.log(text);
    },
    resetVerbindungsmittel: function () {
      var self = this;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);
      self.get('applicationController').set('verbindungsmittelInvalid', true);
      self.get('applicationController').set('ergebnisInvalid', true);
      self.get('downloadcenterController').set('pdfErstellt', false);
      self.get('downloadcenterController').set('dxfErstellt', false);
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('currentScene', 'default');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },
    uebergabedatenErzeugen: function () {
      var self = this;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var applicationdataJSON = JSON.stringify(applicationdata);
      var projektdatendata = self.get('applicationController').get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);
      var geometriedata = self.get('applicationController').get('model').geometrie.objectAt(0);
      var geometriedataJSON = JSON.stringify(geometriedata);
      var lasteinwirkungdata = self.get('applicationController').get('model').lasteinwirkung.objectAt(0);
      var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
      var optionendata = self.get('applicationController').get('model').optionen.objectAt(0);
      var optionendataJSON = JSON.stringify(optionendata);

      if (self.debug) {
        console.log("projektdatendata: " + projektdatendataJSON);
        console.log("applicationdataJSON: " + applicationdataJSON);
        console.log("geometriedataJSON: " + geometriedataJSON);
        console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
        console.log("optionendataJSON: " + optionendataJSON);
      }

      var uebergabedaten = _jquery.default.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(lasteinwirkungdataJSON), JSON.parse(optionendataJSON)); //var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(lasteinwirkungdataJSON), JSON.parse(optionendataJSON));


      var timberelement = {
        "Querschnittswerte": {
          "QuerschnittsID": "0",
          "t_Fastener": geometriedata.get('TimberElement_h') === "" ? "0.0" : geometriedata.get('TimberElement_h'),
          "b": geometriedata.get('TimberElement_b') === "" ? "0.0" : geometriedata.get('TimberElement_b'),
          "h": geometriedata.get('TimberElement_h') === "" ? "0.0" : geometriedata.get('TimberElement_h')
        },
        "vorgebohrt": false,
        "alphaRAD_KraftFaser": geometriedata.get('TE1_alphaRAD_KraftFaser'),
        "alphaRAD_vmFaser": geometriedata.get('TE1_alphaRAD_vmFaser'),
        "betaRAD_vmFurnier": geometriedata.get('TE1_betaRAD_vmFurnier'),
        "MatKey": Number(geometriedata.get('materialIdHolz')),
        "FKLKey": Number(geometriedata.get('festigkeitsIdHolz'))
      };
      var ausklinkung = {
        "h_ef": Number(geometriedata.get('h_ef')),
        "x": Number(geometriedata.get('c')),
        "xi": Number(geometriedata.get('x'))
      };
      uebergabedaten.ausklinkung = ausklinkung; // var berechnungsoptionen = {
      //   "min_n0": Number(optionendata.get('min_n0')),
      //   "min_n90": Number(optionendata.get('min_n90')),
      //   "min_a1cPlatte": Number(optionendata.get('min_a1cPlatte')),
      //   "min_a2cPlatte": Number(optionendata.get('min_a2cPlatte')),
      //   "sicherheitsAbstand": Number(optionendata.get('sicherheitsAbstand'))
      // };

      uebergabedaten.holzbauteil = timberelement; //uebergabedaten.berechnungsoptionen = berechnungsoptionen;

      return uebergabedaten;
    },
    erzeugeAufrufzusatz: function (zweiterzusatz) {
      // Aufrufe  Zusatz1 Zusatz2
      // initialisiereBerechnung  "?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis "?"+kennungland_loadsNA_timberNA  "_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste  "?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe "?"+kennungland_loadsNA_timberNA  "_"+artikelnummer.replace(' ','+')
      // erzeugeDXF "?"+kennungland_loadsNA_timberNA  "_"+artikelnummer.replace(' ','+')
      // speichereBerechnung  "?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45
      var self = this;
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var verbindungsmittel = self.get('verbindungsmittelController');
      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {
        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },
    logaufruf: function (_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {
      var self = this;
      var application = self.get('applicationController');
      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {
        console.log(" ");
        console.log("***** logaufruf von *****");
        console.log(_aufruf);
      }

      var tc = applicationdata.get('timberCode').replace(" ", "").substring(0, 3);
      var lc = applicationdata.get('loadsCode').replace(" ", "").substring(0, 3);
      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = applicationdata.get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + applicationdata.get('Calculation_ID');
      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", applicationdata.get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m08").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", applicationdata.get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", applicationdata.get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", applicationdata.get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", applicationdata.get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", applicationdata.get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", applicationdata.get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": applicationdata.get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": applicationdata.get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("", JSON.stringify(logdaten)); // form.append("", "{\"datum\":12345,\"bid\":\"00000000-0000-0000-0000-000000000000\",\"request\":0,\"firma\":1,\"modul\":0,\"bemessung\":0,\"bemessungNAD\":0,\"lastannahmen\":0,\"lastannahmenNAD\":0,\"eingabeSprache\":0,\"ausgabeSprache\":0,\"katalog\":0,\"methode\":0,\"userIp\":null,\"url\":null,\"status\":0,\"artikelnummer\":4711}");

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice; // var url = "http://assy-bemessung.online/webapi/speichereAktion";
      // var url = "https://onlinebemessung.software/webapi/speichereAktion";

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {
        _jquery.default.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form
        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            console.log("statusText: " + statusText + " ::: status: " + xhr.status); // xhr.status --> 200

            console.log(" ");
          }
        });
      } // testen von der Browserkonsole --> M08.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true, true);

    },
    getHilfeText: function (reiter, steuerElemente, bedingung) {
      return hilfeTexteDB_m08.reiter.findBy("bezeichnung", reiter).steuerElemente.findBy("bezeichnung", steuerElemente).hilfeTexte.findBy("abhaengigkeit", bedingung).hilfetextfelder;
    },
    setHilfetexte: function (controllerName, hilfetextArray) {
      var self = this;
      var controller = self.get(controllerName + 'Controller');

      for (var i = 0; i < hilfetextArray.length; i++) {
        if (hilfetextArray[i].text !== controller.get(hilfetextArray[i].bezeichnung)) {
          controller.set(hilfetextArray[i].bezeichnung + "Focused", true);
        }

        controller.set(hilfetextArray[i].bezeichnung, self.get('intl').t(hilfetextArray[i].text.toString()));
      }
    },
    setHilfetexteDisplayed: function (textProperty) {
      var self = this;
      console.log('textProperty:', textProperty);

      if (textProperty === "" || textProperty.toString().substring(0, 19) === "Missing translation" || typeof textProperty === "undefined" || self.get('intl').t(textProperty) === "") {
        return true;
      } else {
        return true;
      }
    }
  });

  _exports.default = _default;
});