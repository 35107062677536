define("m08-2020/components/lasten-pfeil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Indexedfaceeset = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'components/indexedfacese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    center: Ember.computed('model.firstObject.transformHelper', function () {
      return "0 0 0";
    }),
    translation: Ember.computed('model.firstObject.transformHelper', function () {
      let name = this.get('name');
      let x3d = this.get('model').objectAt(0);
      let traeger = x3d.get('bauteile').findBy('id', 'traeger');
      const geometrieData = this.get('geometrie').objectAt(0);
      const defaultValue = geometrieData.get('h_ef') === "" ? 20 : Number(geometrieData.get('h_ef')) + 0.1;
      const hoehe = geometrieData.get('TimberElement_h') === "" ? defaultValue : Number(geometrieData.get('TimberElement_h'));
      const resttraegerHoehe = geometrieData.get('h_ef') === "" ? hoehe / 2 : Number(geometrieData.get('h_ef'));
      const auflagerHoehe = 1.5;
      let y = hoehe - resttraegerHoehe - auflagerHoehe - 2;
      let z = Number(traeger.get('platte').objectAt(0).get('thickness'));
      return "0 " + y + " " + z / 2;
    }),
    rotation: Ember.computed('model.firstObject.transformHelper', function () {
      return "0 0 0 0";
    }),
    farbe: Ember.computed('model.firstObject.transformHelper', function () {
      let name = this.get('name');
      let x3d = this.get('model').objectAt(0);
      let item = x3d.get('bauteile').findBy('id', name);
      return item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
    }),
    transparency: Ember.computed('model.firstObject.istGitterModell', function () {
      let name = this.get('name');
      let x3d = this.get('model').objectAt(0);
      let item = x3d.get('bauteile').findBy('id', name);
      let value = item.get('appearances').objectAt(0).get('transparency');
      return value;
    }),
    lastText: Ember.computed('model.firstObject.bemessungslast', function () {
      let value = this.get('model').objectAt(0).get('bemessungslast');
      return value;
    }),
    textgroesse: Ember.computed('model.firstObject.{skalierungsfaktor,transformHelper}', function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      var value = 0.3 * skalierungsfaktor;
      return value;
    }),
    farbe: Ember.computed('model.firstObject.bemessungslastAktiv', function () {
      var x3d = this.get('model').objectAt(0);
      return x3d.get('bemessungslastAktiv') ? '1 0 0' : '0 0 0';
    })
  });
  var _default = Indexedfaceeset;
  _exports.default = _default;
});