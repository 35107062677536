define("m08-2020/controllers/geometrie", ["exports", "m08-2020/mixins/object-validator"], function (_exports, _objectValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_objectValidator.default, (_obj = {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: undefined,
    honk: null,
    intl: Ember.inject.service(),
    variante1focused: true,
    variante2focused: false,
    ttHoeheHT: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 10 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttBreiteHT: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 4 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttAuflagerabstand: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttResttraegerhoehe: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.1 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttLaengeAnschnitt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.0 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    applicationController: Ember.inject.controller('application'),
    optionenController: Ember.inject.controller('optionen'),
    lasteinwirkungController: Ember.inject.controller('lasteinwirkung'),
    superController: Ember.inject.controller('supercontroller'),
    x3dController: Ember.inject.controller('x3dcontroller'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    hauptnebentraeger: '',
    breite: "",
    hoehe: "",
    toolTip: "test tooltip",
    ntfkl: 5,
    ntholz: 0,
    httraeger: true,
    validations: {
      breite: {
        numericality: {
          greaterThanOrEqualTo: 4,
          lessThanOrEqualTo: 999.9,
          message: "*"
        }
      },
      hoehe: {
        numericality: {
          greaterThanOrEqualTo: 10,
          lessThanOrEqualTo: 999.9,
          message: "*"
        }
      },
      resttraegerhoehe: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9,
          message: "*"
        }
      },
      auflagerabstand: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9,
          message: "*"
        }
      },
      anschnittlaenge: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999.9,
          message: "*"
        }
      }
    },
    ntmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],
    fkl_vh: [{
      fklind: 0,
      fkl: "C14"
    }, {
      fklind: 1,
      fkl: "C16"
    }, {
      fklind: 2,
      fkl: "C18"
    }, {
      fklind: 3,
      fkl: "C20"
    }, {
      fklind: 4,
      fkl: "C22"
    }, {
      fklind: 5,
      fkl: "C24"
    }, {
      fklind: 6,
      fkl: "C27"
    }, {
      fklind: 7,
      fkl: "C30"
    }, {
      fklind: 8,
      fkl: "C35"
    }, {
      fklind: 9,
      fkl: "C40"
    }, {
      fklind: 10,
      fkl: "C45"
    }, {
      fklind: 11,
      fkl: "C50"
    }],
    fkl_bsh: [{
      fklind: 20,
      fkl: "GL24h"
    }, {
      fklind: 21,
      fkl: "GL28h"
    }, {
      fklind: 24,
      fkl: "GL30h"
    }, {
      fklind: 23,
      fkl: "GL32h"
    }],
    fkl_bsh_kombi: [{
      fklind: 30,
      fkl: "GL24c"
    }, {
      fklind: 31,
      fkl: "GL28c"
    }, {
      fklind: 34,
      fkl: "GL30c"
    }, {
      fklind: 33,
      fkl: "GL32c"
    }],
    ntfklarray: undefined,
    resttraegerhoehe: "",
    auflagerabstand: "",
    anschnittlaenge: "0.0",
    init: function () {
      this._super();
    },
    initialisierung: function () {
      this.setSelectFieldsContent();
      this.set('hauptnebentraeger', this.get('intl').t('bemessungslast').toString());
      this.send('validation', -1, {
        target: {
          name: "foobar"
        }
      });
    },
    setValues: function (values) {
      let self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      let breiteEvent = {
        target: {
          name: "breite"
        }
      };
      let hoeheEvent = {
        target: {
          name: "hoehe"
        }
      };
      let h_efEvent = {
        target: {
          name: "resttraegerhoehe"
        }
      };
      let cEvent = {
        target: {
          name: "auflagerabstand"
        }
      };
      let xEvent = {
        target: {
          name: "anschnittlaenge"
        }
      };
      let geometrie = self.get('applicationController').get('model').geometrie.objectAt(0);
      this.set('openFromFile', true);
      this.set('ntholz', parseInt(values.materialIdHolz));
      this.set('ntfkl', parseInt(values.festigkeitsIdHolz));
      geometrie.set('materialIdHolz', parseInt(values.materialIdHolz));
      geometrie.set('festigkeitsIdHolz', parseInt(values.festigkeitsIdHolz));
      this.set('breite', values.TimberElement_b);
      this.setX3D(parseFloat(values.TimberElement_b), breiteEvent);
      this.set('hoehe', values.TimberElement_h);
      this.setX3D(parseFloat(values.TimberElement_h), hoeheEvent);
      this.set('resttraegerhoehe', values.h_ef);
      this.setX3D(Number(values.h_ef), h_efEvent);
      this.set('auflagerabstand', values.c);
      this.setX3D(Number(values.c), cEvent);
      this.set('anschnittlaenge', values.x);
      this.setX3D(Number(values.x), xEvent);
      this.send('validation');
      this.set('openFromFile', false);
    },
    htBauBuche: Ember.computed('ntholz', function () {
      if (Number(this.get('ntholz')) === 30) {
        return true;
      }

      return false;
    }),
    setSelectFieldsContent: function () {
      var self = this;
      self.setMaterialien();
      self.setFKL();
    },
    setMaterialien: function () {
      var self = this;
      var indices = [0, 1, 13, 12];
      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices, self.get('ntholz')));
    },
    setFKL: function (indices) {
      var self = this;

      if (self.get('ntholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices, self.get('ntfkl')));
        self.set('ntfkl', 20);
      } else if (self.get('ntholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices, self.get('ntfkl')));
        self.set('ntfkl', 30);
      } else if (self.get('ntholz') === 30) {
        indices = [301];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_baubuche', indices, self.get('ntfkl')));
        self.set('ntfkl', 301);
      } else if (self.get('ntholz') === 12) {
        indices = [50, 51, 52];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vollholz_laubholz', indices, self.get('ntfkl')));
        self.set('ntfkl', 50);
      } else if (self.get('ntholz') === 2) {
        indices = [310, 311, 315, 316];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_furnierschichtholz', indices, self.get('ntfkl')));
        self.set('ntfkl', 310);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices, self.get('ntfkl')));
        self.set('ntfkl', 5);
      }

      self.send('setNtFKL', {
        target: {
          value: self.get('ntfkl')
        }
      });
    },
    getSelectFieldContent: function (db, indexes, selectedValue) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({
              id: dbContent[k].id,
              name: this.get('intl').t(dbContent[k].name),
              selected: selectedValue === dbContent[k].id ? true : false
            });
          }
        }
      }

      return tmp;
    },
    getDBContent: function (db) {
      var dbContent = [];

      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de;
          break;

        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;

        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;

        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;

        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;

        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;

        case 'fkl_furnierschichtholz':
          dbContent = fklDB_new.fkl_furnierschichtholz.fklassen;
          break;
      }

      return dbContent;
    },
    getValueFromSelectField: function (contentArray, index) {
      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }

      return tmp;
    },
    watchNumericalInputs: Ember.observer('breite', 'hoehe', function () {
      if (!this.openFromFile) {
        if (this.get('breite').toString().indexOf(",") !== -1) {
          this.set('breite', this.get('breite').toString().replace(",", "."));
        }

        if (this.get('hoehe').toString().indexOf(",") !== -1) {
          this.set('hoehe', this.get('hoehe').toString().replace(",", "."));
        }
      }
    }),

    setNtMaterial(value) {
      var self = this;
      self.get('superController').resetVerbindungsmittel();
      self.set('ntholz', Number(value.target.value));
      var geometrie = self.get('applicationController').get('model').geometrie.objectAt(0);
      geometrie.set('materialIdHolz', Number(value.target.value));
      this.setFKL();
    },

    setNtFKL(value) {
      var self = this;
      self.get('superController').resetVerbindungsmittel();
      self.set('ntfkl', Number(value.target.value));
      var geometrie = self.get('applicationController').get('model').geometrie.objectAt(0);
      geometrie.set('festigkeitsIdHolz', Number(value.target.value));
    },

    removeClass(element) {
      var self = this;
      self.set(element, false);
    },

    // watchComboboxes: observer('ntholz', 'ntfkl', function() {
    //
    //   var self = this;
    //
    //   self.get('superController').resetVerbindungsmittel();
    //
    //   var geometrie = this.get('applicationController').get('model').geometrie.objectAt(0);
    //
    //   geometrie.set('materialIdHolz', this.get('ntholz'));
    //   geometrie.set('festigkeitsIdHolz', this.get('ntfkl'));
    //
    // }),
    setX3D: function (value, event) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var geometrie = self.get('applicationController').get('model').geometrie.objectAt(0);
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'breite':
          if (self.get('errors').get("breite") === undefined) {
            geometrie.set('TimberElement_b', value);
            x3d.set('htBreiteEingetragen', true);
          } else {
            x3d.set('htBreiteEingetragen', false);
          }

          break;

        case 'hoehe':
          if (self.get('errors').get("hoehe") === undefined) {
            geometrie.set('TimberElement_h', value);

            if (Number(geometrie.get('h_ef')) > Number(value)) {
              geometrie.set('h_ef', "");
            }

            self.set('ttResttraegerhoehe', self.get('intl').t('wertebereich') + " 0.1 - " + (Number(value) - 0.1).toFixed(1) + " [cm]");
            self.set('validations.resttraegerhoehe.numericality.lessThanOrEqualTo', Number(value) - 0.1);
            self.set('ttAuflagerabstand', self.get('intl').t('wertebereich') + " 0.1 - " + Number(value).toFixed(1) + " [cm]");
            self.set('validations.auflagerabstand.numericality.lessThanOrEqualTo', Number(value));
            x3d.set('htHoeheEingetragen', true);
          } else {
            x3d.set('htHoeheEingetragen', false);
          }

          break;

        case 'resttraegerhoehe':
          if (self.get('errors').get("resttraegerhoehe") === undefined) {
            geometrie.set('h_ef', value);
            x3d.set('resttraegerhoeheEingetragen', true);
          } else {
            x3d.set('resttraegerhoeheEingetragen', false);
          }

          break;

        case 'auflagerabstand':
          if (self.get('errors').get("auflagerabstand") === undefined) {
            geometrie.set('c', value);
            x3d.set('auflagerabstandEingetragen', true);
          } else {
            x3d.set('auflagerabstandEingetragen', false);
          }

          break;

        case 'anschnittlaenge':
          if (self.get('errors').get("anschnittlaenge") === undefined) {
            geometrie.set('x', value);
            x3d.set('anschnittlaengeEingetragen', true);
          } else {
            x3d.set('anschnittlaengeEingetragen', false);
          }

          break;
      }

      self.get('x3dController').aktualisiereGrafik();
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.get('applicationController').zentriertObjekt();
    },

    validation(value, event) {
      var self = this;

      if (self.validate() === true) {
        self.set('displayErrors', false);
        self.get('applicationController').set('geometrieInvalid', false);
        self.get('superController').resetVerbindungsmittel();

        if (self.get('optionenController').get('einschraubrichtung') === true) {
          self.get('optionenController').set('ttSetztiefe', self.get('intl').t('wertebereich') + " 0.0 - " + self.get('resttraegerhoehe') + " [cm]");
          self.get('optionenController').set('validations.setztiefe.numericality.lessThanOrEqualTo', Number(self.get('resttraegerhoehe')));
        } else {
          var tmp = (parseFloat(self.get('hoehe')) - parseFloat(self.get('resttraegerhoehe'))).toFixed(1);
          self.get('optionenController').set('ttSetztiefe', self.get('intl').t('wertebereich') + " 0.0 - " + tmp + " [cm]");
          self.get('optionenController').set('validations.setztiefe.numericality.lessThanOrEqualTo', Number(tmp));
        }

        self.get('optionenController').send('validation', Number(self.get('optionenController').get('setztiefe')), {
          target: {
            name: "setztiefe"
          }
        });
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('geometrieInvalid', true);
      }

      if (!self.openFromFile) {
        this.setX3D(value, event);
      }
    },

    eingabefeldIsSelected(inputName) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var aktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get(inputName + 'Aktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set(inputName + 'Aktiv', !aktiv);

      if (aktiv === true) {
        bauteile.findBy('id', 'masskette' + inputName.charAt(0).toUpperCase() + inputName.slice(1)).get('diffusecolors').objectAt(0).set('rot', 0);
      } else {
        bauteile.findBy('id', 'masskette' + inputName.charAt(0).toUpperCase() + inputName.slice(1)).get('diffusecolors').objectAt(0).set('rot', 1);
      }

      x3d.set('istAktivHelper', !x3d.get('istAktivHelper'));
      var focused = self.get(inputName + 'Focused');
      self.set(inputName + 'Focused', !self.get(inputName + 'Focused'));

      if (focused === true && self.get(inputName) !== "") {
        var work = parseFloat(this.get(inputName).toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set(inputName, rounded);
        this.send('validation', rounded, {
          target: {
            name: inputName
          }
        });
      }

      if (focused === false && this.get(inputName).length > 0) {
        document.getElementsByName(inputName)[0].setSelectionRange(0, this.get(inputName).length);
      }
    },

    setSchraubenbildWerte(te, _alphaRAD_KraftFaser, _alphaRAD_vmFaser, _betaRAD_vmFurnier, focusedButton, unfocusedButton) {
      var self = this;
      self.get('superController').resetVerbindungsmittel();
      var geometrie = self.get('applicationController').get('model').geometrie.objectAt(0);
      geometrie.set(te + '_alphaRAD_KraftFaser', _alphaRAD_KraftFaser);
      geometrie.set(te + '_alphaRAD_vmFaser', _alphaRAD_vmFaser);
      geometrie.set(te + '_betaRAD_vmFurnier', _betaRAD_vmFurnier);
      self.set(focusedButton, true);
      self.set(unfocusedButton, false);
      console.log(focusedButton + " " + self.get(focusedButton));
      console.log(unfocusedButton + " " + self.get(unfocusedButton));
    },

    initTrigger: true,
    laengeAnschnittFocused: false,
    laengeAnschnittHilfetext: "",
    laengeAnschnittHilfetextFocused: false,
    te2vorgebohrt: true,
    te2vorgebohrtFocused: false,
    te2vorgebohrtHilfetext: "",
    te2vorgebohrtHilfetextFocused: false,
    laengeAnschnittHilfetext: Ember.computed('foobar', function () {// console.log("http://timberconnect.de/externalAssets/mouseoverhtml/SWG/m08/definitionAusklinkung_DE.html");
      // console.log(load("http://timberconnect.de/externalAssets/mouseoverhtml/SWG/m08/definitionAusklinkung_DE.html"));
      // console.log($.load("http://timberconnect.de/externalAssets/mouseoverhtml/SWG/m08/definitionAusklinkung_DE.html"));
      // return $.load("http://timberconnect.de/externalAssets/mouseoverhtml/SWG/m08/definitionAusklinkung_DE.html")
    }),
    observes_laengeAnschnittHilfetext: Ember.observer('laengeAnschnitt', 'initTrigger', function () {
      var self = this;
      var hilfetextArray = self.get('superController').getHilfeText("Geometrie", "laengeAnschnitt", 0 .toString());
      self.get("superController").setHilfetexte("geometrie", hilfetextArray);
    }),
    display_laengeAnschnittHilfetext: Ember.computed('laengeAnschnittHilfetext', function () {
      var self = this;
      return self.get("superController").setHilfetexteDisplayed(self.get('laengeAnschnittHilfetext'));
    })
  }, (_applyDecoratedDescriptor(_obj, "setNtMaterial", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setNtMaterial"), _obj), _applyDecoratedDescriptor(_obj, "setNtFKL", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setNtFKL"), _obj), _applyDecoratedDescriptor(_obj, "removeClass", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "removeClass"), _obj), _applyDecoratedDescriptor(_obj, "validation", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj), _applyDecoratedDescriptor(_obj, "eingabefeldIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "eingabefeldIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "setSchraubenbildWerte", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setSchraubenbildWerte"), _obj)), _obj));

  _exports.default = _default;
});